import React, { Component } from 'react';
import './App.css';
import './index.css'
import './bootstrap-4/css/bootstrap.min.css';
import { ThemeContext } from './components/Core/ThemeContext';
import Banner from './components/Core/Banner';
import InputGrid from './components/Input/InputGrid';
import { SubheaderText, SmallGutter, HeaderText, LargeGutter, MiniTitle } from './components/Core/TextComponents';
import { DailyLocalStorageStore, LocalStorage } from './components/Storage/LocalStorage';
import { Button } from 'react-bootstrap';
import AddShortcut from './components/Input/AddShortcut';
import DaySwitch from './components/Input/DaySwitch';
import IconGrid from './components/Input/IconGrid';
import { Home, Settings, InfoOutlined, HelpOutlines, SearchOutlined, HelpOutlined } from '@material-ui/icons';
import { FastfoodOutlined, Restaurant, LocalDining } from '@material-ui/icons';
import { FitnessCenter, LineWeightOutlined, AccessAlarm } from '@material-ui/icons';
import { RemoveCircle, DeleteOutlined, FiberManualRecord, MusicNoteOutlined } from '@material-ui/icons';
import { SearchOutlines, SearchOff, FilterList } from '@material-ui/icons';
import AddCircleOutlineOutlinedIcon from '@material-ui/icons/AddCircleOutlineOutlined';
import HelpOutlineOutlinedIcon from '@material-ui/icons/HelpOutlineOutlined';
import AssessmentOutlinedIcon from '@material-ui/icons/AssessmentOutlined';
import HourglassEmptyOutlinedIcon from '@material-ui/icons/HourglassEmptyOutlined';
import { Divider } from '@material-ui/core';
import * as Swetrix from 'swetrix'

class Labs extends Component {
    static contextType = ThemeContext;

    

    data = {
        "selfcare": {
            icons : [FastfoodOutlined, LineWeightOutlined, AddCircleOutlineOutlinedIcon], // Icons passed in
            labels : ["calories", "weight", "counter"],
            targets : ["/labs/calories", "/labs/weight", "/labs/counter"]
        },
        "bible": {
            icons : [SearchOutlined], // Icons passed in
            labels : ["bible search"],
            targets : ["/search"]
        },
        "admin": {
            icons : [HelpOutlineOutlinedIcon, DeleteOutlined, InfoOutlined ], // Icons passed in
            labels : ["help", "manage data", "debug"],
            targets : [ "/help", "/labs/deleteData", "/labs/debug"]
        },
        "music": {
            icons : [MusicNoteOutlined, MusicNoteOutlined], // Icons passed in
            labels : ["scale-chords", "scale-notes"],
            targets : ["/labs/chords", "/labs/notes"]
        }
        // ,
        // "music": {
        //     icons : [MusicNoteOutlined, MusicNoteOutlined, HourglassEmptyOutlinedIcon], // Icons passed in
        //     labels : ["scale-chords", "scale-notes", "metronome"],
        //     targets : ["/labs/chords", "/labs/notes", "/labs/metronome"]
        // }
    }

    handleIconClick = (index, targets) => {
        const target = targets[index];
        console.log(`Icon ${index} clicked ${target} `);
        Swetrix.track({
            ev: `labs.${target}`,
            unique: false,
        })
        window.location = target;
    };

    constructor() {
        super();
        this.handleIconClick = this.handleIconClick.bind(this)
    }

    render() {
        return (
            <>
                <Banner text="Experimental apps as stepping stones towards greater functionality for ai-Bible"></Banner>
                <div style={{minHeight:'400px', height:'100vh',margin:'auto', padding: '20px'}} className="graphpaper">
                    
                    
                    <LargeGutter></LargeGutter>
                    <MiniTitle>Bible</MiniTitle>
                    <Divider light />
                    <LargeGutter></LargeGutter>
                    <IconGrid
                        icons={this.data.bible.icons}
                        labels={this.data.bible.labels}
                        onIconClick={(index)=>{this.handleIconClick(index, this.data.bible.targets)}}                        
                    />
                    <SmallGutter></SmallGutter>

                    <LargeGutter></LargeGutter>
                    <MiniTitle>Self Care</MiniTitle>
                    <Divider light />
                    <LargeGutter></LargeGutter>
                    <IconGrid
                        icons={this.data.selfcare.icons}
                        labels={this.data.selfcare.labels}
                        onIconClick={(index)=>{this.handleIconClick(index, this.data.selfcare.targets)}}                        
                    />
                    <SmallGutter></SmallGutter>

                    <LargeGutter></LargeGutter>
                    <MiniTitle>Music</MiniTitle>
                    <Divider light />

                    <IconGrid
                        icons={this.data.music.icons}
                        labels={this.data.music.labels}
                        onIconClick={(index)=>{this.handleIconClick(index, this.data.music.targets)}}                        
                    />
                    <SmallGutter></SmallGutter>

                    <LargeGutter></LargeGutter>
                    <MiniTitle>Admin</MiniTitle>
                    <Divider light />

                    <IconGrid
                        icons={this.data.admin.icons}
                        labels={this.data.admin.labels}
                        onIconClick={(index)=>{this.handleIconClick(index, this.data.admin.targets)}}                        
                    />
                    <SmallGutter></SmallGutter>
                </div>
                
            </>
        );
    }
}

export default Labs;