import React, { Component } from 'react';
import '../../App.css';
import '../../bootstrap-4/css/bootstrap.min.css';
import { ThemeContext } from '../../components/Core/ThemeContext';
import Banner from '../../components/Core/Banner';
import { SubheaderText, SmallGutter, HeaderText } from '../../components/Core/TextComponents';
import scales from '../../data/scales.json';
import { ButtonGroup, Button } from '@material-ui/core';
import RadioButtonGroup from '../../components/Input/RadioButtonGroup';
import { GuitarChord } from '../../components/Input/GuitarFretboard';
//import PainoButtonGroup from './components/Input/PianoButtonGroup';

const queryParams = new URLSearchParams(window.location.search);
//onst caloriesParam = parseInt(queryParams.get('calories'));

class Empty extends Component {
    static contextType = ThemeContext;

    constructor() {
        super();

        this.state = {
            contacts: [],
            search: 'John.12.1-John.12.11',
            previoussearch: 'John.12.1-John.12.11',
            unit: 'cal',
            key: 'A',
            scaleType: 'Major',
            chord: 'A',
        };
    }

    static getDerivedStateFromError() {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        
        this.context.loading(false);
    }

    killLoader = function () {
        this.setState({ contacts: [] });
    };

    onScaleTypeSelected = ((val)=>{
        this.setState({scaleType: val});
    })

    onScaleSelected = ((val)=>{
        this.setState({key: val});
    })

    onChordSelected = ((val)=>{
        this.setState({chord: val});
    })


    render() {
        const keys = Object.keys(scales);
        const scaleTypes = Object.keys(scales[this.state.key]);
        const chords = scales[this.state.key][this.state.scaleType].chords

        const abbreviatedChords = chords.map((chord) => {
            return chord
              .replace(" major", "")  // Remove 'major'
              .replace(" minor", "m") // Replace 'minor' with 'm'
              .replace(" diminished", "dim"); // Replace 'diminished' with 'dim'
          });
       
        console.log({scaleTypes})
        document.title = 'ai|Emoty';
        //console.log(`render: ${this.state.contacts}`);
        return (
            <div style={{
                background: '#fcfcfc',
                padding: 0,
                margin: 0,
                width: '100%'
            }}
            >
                <div style={{
                    height: 1, backgroundColor: 'lightgrey', padding: 0, margin: 0
                }}
                />
                <div style={{ margin: 20, maxWidth: 500}}>
                    <div style={{ margin: 20}}>
                        <HeaderText> Chords From Scale </HeaderText>
                        <SmallGutter></SmallGutter>
                        <SubheaderText>Mode of Scale</SubheaderText>
                        <SmallGutter></SmallGutter>
                        <RadioButtonGroup buttons={ scaleTypes } selected={this.state.scaleType} onSelect={this.onScaleTypeSelected}></RadioButtonGroup>
                        <SmallGutter></SmallGutter>
                        <SubheaderText>Key of Scale</SubheaderText>
                        <SmallGutter></SmallGutter>
                        <RadioButtonGroup buttons={ keys } selected={this.state.key} onSelect={this.onScaleSelected}></RadioButtonGroup>
                        <SmallGutter></SmallGutter>
                        <SubheaderText>Chord from Scale</SubheaderText>
                        <SmallGutter></SmallGutter>
                        <RadioButtonGroup buttons={ abbreviatedChords } selected={this.state.chord} onSelect={this.onChordSelected}></RadioButtonGroup>
                        {/* <PainoButtonGroup></PainoButtonGroup> */}
                        <SmallGutter></SmallGutter>
                        <SmallGutter></SmallGutter>
                        <SmallGutter></SmallGutter>
                        <SmallGutter></SmallGutter>
                        <GuitarChord chord={this.state.chord}></GuitarChord>
                        
                        <SmallGutter></SmallGutter>
                    </div>
                </div>
            </div>
        );
    }
}

export default Empty;
