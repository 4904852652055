import React, { useState, useEffect, useRef } from 'react';
import * as Tone from 'tone';
import { Button, Slider } from '@material-ui/core';
import { LargeGutter } from '../../components/Core/TextComponents';

const Metronome = () => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [tempo, setTempo] = useState(120); // BPM
  const [debouncedTempo, setDebouncedTempo] = useState(120); // Debounced Tempo
  const [clickPlayer1, setClickPlayer1] = useState(null); // Player for the first beat
  const [clickPlayer2, setClickPlayer2] = useState(null); // Player for the other beats
  const [isLoaded, setIsLoaded] = useState(false); // To track if both sounds are loaded
  const [isLoading, setIsLoading] = useState(false); // To track if both sounds are loaded
  const [isSliderDragging, setIsSliderDragging] = useState(false); // Track slider interaction
  const beatCounter = useRef(0); // Tracks the current beat
  const timeoutRef = useRef(null); // Stores the timeout reference

  const loadSounds = () => {
    setIsLoading(true);

    const url1 = window.web_version?`${process.env.PUBLIC_URL}/sounds/ClickG3.mp3`: Capacitor.convertFileSrc('/sounds/ClickG3.mp3');
    const url2 = window.web_version?`${process.env.PUBLIC_URL}/sounds/ClickD3.mp3`: Capacitor.convertFileSrc('/sounds/ClickD3.mp3');

    // const url1 = './ClickG3.mp3'
    // const url2 = './ClickD3.mp3'

    console.log('Loading url: ', url1);

    const player1 = new Tone.Player({
        url: url1, // Path for the first beat sound
        onload: () => {
          setIsLoaded(true); // Mark as loaded when ready
        },
      }).toDestination();
  
      const player2 = new Tone.Player({
        url: url2, // Path for the other beat sounds
        onload: () => {
          setIsLoaded(true);
        },
      }).toDestination();
  
      setClickPlayer1(player1);
      setClickPlayer2(player2);
  }

  // Load click sounds
  useEffect(() => {

    // const player1 = new Tone.Player({
    //   url: `${window.location.origin}/sounds/ClickG3.mp3`, // Path for the first beat sound
    //   onload: () => {
    //     setIsLoaded(true); // Mark as loaded when ready
    //   },
    // }).toDestination();

    // const player2 = new Tone.Player({
    //   url: `${window.location.origin}/sounds/ClickD3.mp3`, // Path for the other beat sounds
    //   onload: () => {
    //     setIsLoaded(true);
    //   },
    // }).toDestination();

    // setClickPlayer1(player1);
    // setClickPlayer2(player2);

    // Cleanup players
    return () => {
    //   player1.dispose();
    //   player2.dispose();
    };
  }, []);

  // Function to play the current beat and schedule the next beat
  const playBeat = () => {
    if (!clickPlayer1 || !clickPlayer2) return;

    // Play the correct sound for the current beat
    if (beatCounter.current % 4 === 0) {
      clickPlayer1.start(); // Play the first beat sound
    } else {
      clickPlayer2.start(); // Play the other beat sound
    }

    // Increment beat counter (looping back after 4 beats)
    beatCounter.current = (beatCounter.current + 1) % 4;

    // Calculate the time for the next beat based on the current tempo
    const nextInterval = (60 / debouncedTempo) * 1000;

    // Schedule the next beat
    timeoutRef.current = setTimeout(playBeat, nextInterval);
  };

  // Start the metronome
  const startMetronome = () => {
    if (!isLoaded) loadSounds(); // Ensure sounds are loaded
    setIsPlaying(true);
    beatCounter.current = 0; // Reset beat counter
    playBeat(); // Start the metronome
  };

  // Stop the metronome
  const stopMetronome = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current); // Clear the timeout
    }
    setIsPlaying(false);
  };

  // Handle tempo changes in real-time
  useEffect(() => {
    if (isPlaying) {
      // If the metronome is playing, update the timing immediately
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
      playBeat(); // Restart the beat schedule with the new tempo
    }
  }, [debouncedTempo]); // Dependency on tempo changes

  const handleSliderChange = (event, newValue) => {
    setTempo(newValue); // Update tempo state with slider value
    setIsSliderDragging(true); // Indicate slider is being dragged
  };

  // Commit slider value changes and unmute
  const handleSliderChangeCommitted = (event, newValue) => {
    setIsSliderDragging(false); // Indicate slider dragging has stopped
    setDebouncedTempo(newValue); // Update the debounced tempo
    // Restore the click players' volume
    // if (clickPlayer1 && clickPlayer2) {
    //   clickPlayer1.volume.value = 0;
    //   clickPlayer2.volume.value = 0;
    // }
  };

  const mark = (val) => {
    return {
        value: val,
        label: val + ""
    }
  }

  return (
    <div style={{ textAlign: 'center', marginTop: '50px' }}>
      <h1>Metronome</h1>
      <div>
        <Button variant="contained" color="primary" onClick={loadSounds} disabled={isLoading}>
            {isLoading ? 'Load' : 'Load'}
        </Button>
        <Button variant="contained" color="primary" onClick={isPlaying ? stopMetronome : startMetronome} disabled={!isLoaded}>
            {isPlaying ? 'Stop' : 'Start'}
        </Button>
        {/* { isLoading?<>
            
        </>:<>
            <Button variant="contained" color={isLoading?"default":"primary"} onClick={isLoading ? stopMetronome : startMetronome} disabled={!isLoaded}>
            {isLoading ? 'Loading' : 'Load'}
            </Button>
        </>} */}

      </div>
      <LargeGutter></LargeGutter>
      <div style={{marginLeft:'20px', marginRight:'20px'}}>
        <label>Tempo: </label>
        {/* <input
          type="number"
          value={tempo}
          onChange={(e) => setTempo(Number(e.target.value))}
          min="40"
          max="200"
        /> */}
        <Slider
          value={tempo}
          onChange={handleSliderChange}
          onChangeCommitted={handleSliderChangeCommitted}
          min={40}
          max={200}
          step={1}
          aria-labelledby="tempo-slider"
          marks={[40,60, 80, 100, 120, 144, 168, 200].map(x=>mark(x))}
          valueLabelDisplay="on"
          style={{marginTop:'40px'}}
        />
      </div>
      {(!isLoaded && isLoading) && <p>Loading sounds...</p>} {/* Display loading message until ready */}
    </div>
  );
};

export default Metronome;
