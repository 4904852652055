/* eslint-disable max-classes-per-file */
import React from 'react';
import styled from 'styled-components';
import Typography from '@material-ui/core/Typography';
import { ThemeContext } from './ThemeContext';

// const Title = styled.h2`
//   color: #000;
//   font-weight: 300;
// `
// const Date = styled.div`
//   color: #ccc;
//   font-weight: 300;
//   margin: 6px 0;
// `
// const Block = styled.div`
//     display: float;
//     margin: auto
// `

const Container = styled.div`
  margin: 20 important!;
  padding: 20 important!;
  border-bottom: 1px dotted #81ABDF44;
  border-top: 0px dotted #c0e0ff;
`;
// const Description = styled.p`
//   color: #000;
//   font-weight: 300;
//   margin: 0 important!;
// `

const SmallGutter = styled.div`
  min-height: 5px;
`;

const LargeGutter = styled.div`
  min-height: 8px;
`;

const InlineDiv = styled.div`
  display: inline;
`;

const InlineSpan = styled.span`
  display: inline;
`;

class DisabledText extends React.Component {
  static contextType = ThemeContext;

  render() {
      const theStyle = {
          color: this.context.disabledtext,
          padding: 0,
          paddingLeft: 0,
          paddingBottom: 4,
          margin: 0,
          boxSizing: 'border-box',
          display: 'inline',
      };
      return (
          <Typography style={theStyle} onClick={this.props.onClick} variant="body1" component="p">
              {this.props.children}
              <br />
          </Typography>
      );
  }
}

class CommentText extends React.Component {
    static contextType = ThemeContext;

    render() {
        const theStyle = {
            color: (this.props.highlight) ? this.context.highlight : this.context.softtext,
            padding: 0,
            paddingLeft: 0,
            paddingBottom: 4,
            margin: 0,
            boxSizing: 'border-box',
            display: 'inline',
            fontSize: '0.9em',
        };
        return (
            <Typography style={theStyle} onClick={this.props.onClick} variant="body1" component="p">
                {this.props.children}
                <br />
            </Typography>
        );
    }
}

class CommentHeader extends React.Component {
    static contextType = ThemeContext;

    render() {
        const theStyle = {
            color: (this.props.highlight) ? this.context.highlight : this.context.foreground,
            padding: 0,
            paddingLeft: 0,
            paddingBottom: 4,
            margin: 0,
            boxSizing: 'border-box',
            display: 'inline',
            fontSize: '0.9em',
            fontWeight: 'bold'
        };
        return (
            <Typography style={theStyle} onClick={this.props.onClick} variant="body1" component="p">
                {this.props.children}
                <br />
            </Typography>
        );
    }
}

class CommentNotes extends React.Component {
    static contextType = ThemeContext;

    render() {
        const theStyle = {
            color: (this.props.highlight) ? this.context.highlight : this.context.softtext,
            opacity: '0.7',
            padding: 0,
            paddingLeft: 0,
            paddingBottom: 4,
            margin: 0,
            boxSizing: 'border-box',
            display: 'inline',
            fontSize: '0.8em',
            fontStyle: 'italic'
        };
        return (
            <Typography style={theStyle} onClick={this.props.onClick} variant="body1" component="p">
                {this.props.children}
                <br />
            </Typography>
        );
    }
}

class BodyText extends React.Component {
    static contextType = ThemeContext;

    render() {
        const theStyle = {
            color: (this.props.highlight) ? this.context.highlight : this.context.softtext,
            padding: 0,
            paddingLeft: 0,
            paddingBottom: 4,
            margin: 0,
            boxSizing: 'border-box',
            display: 'inline',
            fontSize: '1.03em',
        };
        return (
            <Typography style={theStyle} onClick={this.props.onClick} variant="body1" component="p">
                {this.props.children}
                <br />
            </Typography>
        );
    }
}

class BodyTextInline extends React.Component {
    static contextType = ThemeContext;

    render() {
        const theStyle = {
            color: this.props.color ? this.props.color : this.context.softtext,
            padding: 0,
            paddingLeft: 0,
            paddingBottom: 4,
            margin: 0,
            boxSizing: 'border-box',
            display: 'inline',
            wordWrap: 'break-word',
            overflowWrap: 'break-word',
            overflow: 'wrap',
        };
        return (
            <Typography style={theStyle} onClick={this.props.onClick} variant="body1" component="p">
                {this.props.children}
            </Typography>
        );
    }
}

class BodyTextAreaInline extends React.Component {
  static contextType = ThemeContext;

  render() {
      const theStyle = {
          color: this.props.color ? this.props.color : this.context.softtext,
          padding: 0,
          paddingLeft: 0,
          paddingBottom: 4,
          margin: 0,
          boxSizing: 'border-box',
          display: 'inline-block',
          wordWrap: 'break-word',
          overflowWrap: 'break-word',
          overflow: 'scroll',
          overflowInline: 'scroll',
          overflowY: 'scroll',
      };
      return (
          <p style={theStyle} onClick={this.props.onClick} variant="body1" component="p">
              {this.props.children}
          </p>
      );
  }
}

class FootnotelinkInline extends React.Component {
    static contextType = ThemeContext;

    render() {
        const theStyle = {
            color: this.props.color ? this.props.color : '#00F',
            padding: 0,
            paddingLeft: 0,
            paddingBottom: 4,
            margin: 0,
            boxSizing: 'border-box',
            display: 'inline',
            cursor: 'pointer',
            fontSize: '0.6em',
        };
        return (
            <a
                style={theStyle}
                onClick={this.props.onClick}
                variant="body2"
                component="p"
                href={this.props.href}
            >
                {this.props.children}
            </a>
        );
    }
}

class FootnoteInline extends React.Component {
    static contextType = ThemeContext;

    render() {
        const theStyle = {
            color: this.props.color ? this.props.color : this.context.softtext,
            padding: 0,
            paddingLeft: 0,
            paddingBottom: 4,
            margin: 0,
            boxSizing: 'border-box',
            display: 'inline',
            fontSize: '0.6em',
        } || this.props.style;
        return (
            <Typography style={theStyle} onClick={this.props.onClick} variant="body2" component="p">
                {this.props.children}
            </Typography>
        );
    }
}

class Footnote extends React.Component {
    static contextType = ThemeContext;

    render() {
        const theStyle = {
            color: this.props.color ? this.props.color : this.context.softtext,
            padding: 0,
            paddingLeft: 0,
            paddingBottom: 4,
            margin: 0,
            boxSizing: 'border-box',
            fontStyle: 'italic',
        } || this.props.style;
        return (
            <Typography style={theStyle} onClick={this.props.onClick} variant="body2" component="p">
                {this.props.children}
            </Typography>
        );
    }
}

class MiniTitle extends React.Component {
    static contextType = ThemeContext;

    render() {
        const theStyle = {
            color: this.props.color ? this.props.color : this.context.softtext,
            padding: 0,
            paddingLeft: 0,
            paddingBottom: 4,
            margin: 0,
            boxSizing: 'border-box',
            fontWeight: 'bold !important'
        } || this.props.style;
        return (
            <Typography style={theStyle} onClick={this.props.onClick} variant="body2" component="p">
                <strong>{this.props.children}</strong>
            </Typography>
        );
    }
}

class IconTitle extends React.Component {
    static contextType = ThemeContext;

    render() {
        const theStyle = {
            color: this.props.color ? this.props.color : '#888',
            padding: 0,
            paddingLeft: 0,
            paddingBottom: 4,
            margin: 0,
            boxSizing: 'border-box',
            fontStyle: '',
            fontSize: '0.82em',
        } || this.props.style;
        return (
            <Typography style={theStyle} onClick={this.props.onClick} variant="body2" align="center" component="p">
                {this.props.children}
            </Typography>
        );
    }
}

class BodyLink extends React.Component {
    static contextType = ThemeContext;

    render() {
        const theStyle = {
            color: this.props.color ? this.props.color : '#00F',
            padding: 0,
            paddingLeft: 0,
            paddingBottom: 4,
            margin: 0,
            boxSizing: 'border-box',
            display: 'inline',
            cursor: 'pointer',
        };
        return (
            <a style={theStyle} onClick={this.props.onClick} variant="body1" component="p" href={this.props.href}>
                {this.props.children}
                <br />
            </a>
        );
    }
}

class BodyLinkInline extends BodyLink {
    static contextType = ThemeContext;

    render() {
        const theStyle = {
            color: this.props.color ? this.props.color : '#00F',
            padding: 0,
            paddingLeft: 0,
            paddingBottom: 4,
            margin: 0,
            boxSizing: 'border-box',
            display: 'inline',
            cursor: 'pointer',
        };
        return (
            <a style={theStyle} onClick={this.props.onClick} variant="body1" component="p" href={this.props.href}>
                {this.props.children}
            </a>
        );
    }
}

class IndexText extends React.Component {
    static contextType = ThemeContext;

    render() {
        const theStyle = {
            color: this.context.softlink,
            textAlign: 'right',
            verticalAlign: 'bottom',
            padding: 4,
            marginTop: 4,
            minWidth: 30,
            fontFamily: this.context.scriptfont,
            fontSize: '0.9em',
        };
        return (
            <h4 style={theStyle} variant="body1" component="p">
                {this.props.children}
                <br />
            </h4>
        );
    }
}

class IndexTextInline extends React.Component {
    static contextType = ThemeContext;

    render() {
        const theStyle = {
            color: this.context.softlink,
            padding: 0,
            paddingLeft: 0,
            paddingBottom: 4,
            margin: 0,
            boxSizing: 'border-box',
            display: 'inline',
            wordWrap: 'break-word',
            overflowWrap: 'break-word',
            overflow: 'wrap',
        };
        return (
            <Typography style={theStyle} onClick={this.props.onClick} variant="body1" component="p">
                {this.props.children}
            </Typography>
        );
    }
}

class HeaderText extends React.Component {
    static contextType = ThemeContext;

    render() {
        const theStyle = {
        // color: this.context.foreground,
            padding: 0,
            paddingLeft: 0,
            margin: 0,
            boxSizing: 'border-box',
            display: 'inline',
            fontFamily: this.context.scriptfont,
            fontSize: '2.2em',
        };

        return (
            <h1 style={theStyle} variant="h4" component="h4" className={this.props.className}>
                {this.props.children}
                <br />
            </h1>
        );
    }
}

class HeaderTextSmall extends React.Component {
    static contextType = ThemeContext;

    render() {
        const theStyle = {
        // color: this.context.foreground,
            padding: 0,
            paddingLeft: 0,
            margin: 0,
            boxSizing: 'border-box',
            display: 'inline',
            fontFamily: this.context.scriptfont,
            fontSize: '1.4em',
        };

        return (
            <h1 style={theStyle} variant="h4" component="h4" className={this.props.className}>
                {this.props.children}
                <br />
            </h1>
        );
    }
}

class HeaderExt extends React.Component {
    static contextType = ThemeContext;

    render() {
        const theStyle = {
            color: this.context.foreground,
            paddingLeft: 0,
            marginBottom: '-20px !important',
            paddingBottom: '-20px !important',
            boxSizing: 'border-box',
            display: 'block',
        };
        return (
            <div>
                <Typography style={theStyle} variant="h5" component="h5">
                    {this.props.children}
                </Typography>
                {(this.props.description) ? <small style={{ marginTop: -20, paddingTop: -20 }} className="mt-0">{this.props.description}</small> : null}
                <SmallGutter />
            </div>
        );
    }
}

class SubheaderText extends React.Component {
    static contextType = ThemeContext;

    render() {
        const theStyle = {
            color: this.context.foreground,
            paddingLeft: 0,
            marginBottom: 12,
            paddingBottom: 12,
            boxSizing: 'border-box',
            display: 'inline',
        };
        return (
            <div>
                <Typography style={theStyle} variant="h6" component="h6">
                    {this.props.children}
                    <br />
                </Typography>
                <SmallGutter />
            </div>
        );
    }
}

class SourceCredit extends React.Component {
    static contextType = ThemeContext;

    render() {
        const theStyle = {
            color: this.context.foreground,
            padding: 0,
            paddingLeft: 0,
            margin: 0,
            boxSizing: 'border-box',
            display: 'inline',
        };
        console.log('->SourceCredit', this.props.copyright);
        return (
            <div style={theStyle}>
                <BodyTextInline style={theStyle}>
                    {this.props.copyright}
                </BodyTextInline>
                <BodyLinkInline href={this.props.link} style={theStyle}>
                    {this.props.license}
                </BodyLinkInline>
                <BodyTextInline style={theStyle}>
                    {this.props.postfix}
                </BodyTextInline>
            </div>
        );
    }
}

class SourceCredits extends React.Component {
    static contextType = ThemeContext;

    createMarkup(source) {
        return { __html: source };
    }

    render() {
        // console.log("RenderSourceCredits: " + new Date().getSeconds());
        return (
            <div>
                <Footnote>&nbsp;</Footnote>
                <ol style={{ margin: 0, padding: 0 }}>
                    {
                        (this.props.sources)
                            ? this.props.sources.map((value, index) => {
                                value.source = value.source.replace('Source: ', '');
                                return (
                                    <div key={index}>
                                        <div>
                                            <FootnoteInline>
                                                <b>Source(s):</b>
                                    &nbsp;
                                            </FootnoteInline>
                                            <FootnoteInline>
                                                <span dangerouslySetInnerHTML={this.createMarkup(value.source)} />
                                  &nbsp;
                                            </FootnoteInline>
                                            <FootnotelinkInline href={value.link}>
                                                {value.linklabel}
                                    &nbsp;
                                            </FootnotelinkInline>
                                            <FootnoteInline>
                                                {value.postfix}
                                    &nbsp;
                                            </FootnoteInline>
                                            <FootnotelinkInline href={value.postlink}>
                                                {value.postlinktext}
                                            </FootnotelinkInline>
                                        </div>
                                    </div>
                                );
                            }) : null
                    }
                </ol>
            </div>
        );
    }
}

function FootnoteReference(props) {
    return (
    // <FootnoteReference number={this.props.footnoteStart}/>
        (!props.nolink)
            ? <a href={`#footnote${props.number}`}><sup>{props.number}</sup></a>
            : <sup>{props.number}</sup>

    // <a href={"#footnote"+props.number}><sup>{props.number}</sup></a> link doesn't work well
    );
}

const anchorStyle = {
    display: 'block',
    position: 'relative',
    top: '-100px',
    visibility: 'hidden',
};

class SourceFigureAndFootnotes extends React.Component {
    render() {
        const {
            figureNumber, footnotes, sources, footnoteStart, figureName,
        } = this.props;
        return (
            <>
                {(figureNumber) ? <SourceFigure sources={sources} figureNumber={figureNumber} figureName={figureName} /> : null}
                {(figureNumber) ? <SmallGutter /> : null}
                {(footnotes) ? <SourceFootnotes footnotes={(footnotes) ? [footnotes[0]] : []} footnoteStart={footnoteStart} /> : null}
            </>
        );
    }
}

class SourcePermissions extends React.Component {
  static contextType = ThemeContext;

  createMarkup(source) {
      return { __html: source };
  }

  render() {
      let { sources } = this.props;

      //console.log('SourcePermissions', sources);

      if (!sources) sources = [];

      const permitted = sources.filter((elem) => elem.hasOwnProperty('permissions'));

      // console.log("RenderSourceCredits: " + new Date().getSeconds());
      return (
          <div>
              <ol style={{ margin: 0, padding: 0 }}>
                  {
                      (permitted)
                          ? permitted.map((value, index) => {
                              value.source = value.source.replace('Source: ', '');
                              return (
                                  <div key={index}>
                                      <div>
                                          <FootnoteInline>
                                              <b>Used with kind permission:</b>
                                  &nbsp;
                                          </FootnoteInline>
                                          <FootnoteInline>
                                              <span dangerouslySetInnerHTML={this.createMarkup(value.permissions)} />
                                &nbsp;
                                          </FootnoteInline>
                                          <FootnotelinkInline href={value.link}>
                                              {value.linklabel}
                                  &nbsp;
                                          </FootnotelinkInline>
                                          <FootnoteInline>
                                              {value.postfix}
                                  &nbsp;
                                          </FootnoteInline>
                                          <FootnotelinkInline href={value.postlink}>
                                              {value.postlinktext}
                                          </FootnotelinkInline>
                                      </div>
                                  </div>
                              );
                          }) : null
                  }
              </ol>
          </div>
      );
  }
}

class SourceFigure extends React.Component {
  static contextType = ThemeContext;

  createMarkup(source) {
      return { __html: source };
  }

  render() {
      // console.log("RenderSourceCredits: " + new Date().getSeconds())
      let { sources } = this.props;

      if (!sources) sources = [];

      // console.log("Sources", sources);
      let footnotes = sources.filter((elem) => elem.hasOwnProperty('figure'));

      // console.log("Ffiltered", footnotes);
      footnotes = footnotes.map((elem) => elem.figure);

      // console.log("Footnotes", footnotes);

      return (
          <div
              title={`Figure ${this.props.figureNumber}: ${this.props.figureName}`}
              lightweight
              info
          >
              <FootnoteInline>
                  <b>
                      Figure
                      {this.props.figureNumber}
                      :&nbsp;
                  </b>
                  <span>
                      {' '}
                      {this.props.figureName}
                      {' '}
&nbsp;
                      {' '}
                      {(footnotes.length > 0) ? '(' : null}
                  </span>
                  {footnotes.map((value, index) => (
                      <InlineSpan key={index}>
                          {(index !== 0) ? <span>&nbsp;</span> : null}
                          <span style={{ color: this.context.lighttext }} dangerouslySetInnerHTML={this.createMarkup(value)} />
                      </InlineSpan>
                  ))}
                  <span>{(footnotes.length > 0) ? ')' : null}</span>
          &nbsp;
              </FootnoteInline>
              <ol style={{ margin: 0, padding: 0 }}>
                  {

                  }
              </ol>
          </div>
      );
  }
}

class SourceBibliography extends React.Component {
  static contextType = ThemeContext;

  createMarkup(source) {
      return { __html: source };
  }

  render() {
      // console.log("RenderSourceCredits: " + new Date().getSeconds());
      let counter = 0;

      console.log('SourceBibliography', this.props.bibliography);

      return (
          <div>
              <SmallGutter />
              <ol style={{ margin: 0, padding: 0 }}>
                  {
                      (this.props.bibliography)
                          ? this.props.bibliography.map((value, index) => {
                              counter++;
                              return (
                                  (value.bibliography)
                                      ? (
                                          <div key={counter}>
                                              <FootnoteInline>
                                                  <span style={{ color: this.softtext }} dangerouslySetInnerHTML={this.createMarkup(value.bibliography)} />
                          &nbsp;
                                              </FootnoteInline>
                                              <LargeGutter />
                                          </div>
                                      )
                                      : null
                              );
                          }) : null
                  }
              </ol>
          </div>
      );
  }
}

class SourceFootnotes extends React.Component {
  static contextType = ThemeContext;

  createMarkup(source) {
      return { __html: source };
  }

  render() {
      // console.log("RenderSourceCredits: " + new Date().getSeconds());
      let counter = (this.props.footnoteStart) ? (this.props.footnoteStart - 1) : 0;

      // console.log("SourceFootnotes", this.props.footnotes)

      if (this.props.footnotes) {
          this.props.footnotes.sort((a, b) => {
              if (!a.footnote) return -1;
              if (!b.footnote) return 1;

              if (a.footnote > b.footnote) {
                  return 1;
              }
              if (b.footnote > a.footnote) {
                  return -1;
              }
              return 0;
          });
      }

      return (
          <div>
              <SmallGutter />
              <ol style={{ margin: 0, padding: 0 }}>
                  {
                      (this.props.footnotes)
                          ? this.props.footnotes.map((value, index) => {
                              if (value.footnote) counter += 1;
                              return (
                                  (value.footnote)
                                      ? (
                                          <div key={counter}>
                                              <div id={`footnote${counter}`} style={anchorStyle} />
                                              <sup>{counter}</sup>
                                              <FootnoteInline>
                                                  <span style={{ color: this.context.lighttext }} dangerouslySetInnerHTML={this.createMarkup(value.footnote)} />
                          &nbsp;
                                              </FootnoteInline>
                                          </div>
                                      )
                                      : null
                              );
                          }) : null
                  }
              </ol>
          </div>
      );
  }
}

export {
    InlineDiv,
    Container,
    BodyText,
    BodyTextInline,
    BodyLink,
    BodyLinkInline,
    HeaderText,
    HeaderTextSmall,
    IndexText,
    IndexTextInline,
    SubheaderText,
    SourceCredit,
    SourceCredits,
    SourceFootnotes,
    Footnote,
    FootnoteInline,
    FootnotelinkInline,
    FootnoteReference,
    MiniTitle,
    CommentText,
    CommentHeader,
    CommentNotes,
    BodyTextAreaInline,
    SourceFigure,
    DisabledText,
    SmallGutter,
    LargeGutter,
    IconTitle,
    HeaderExt,
    SourceBibliography,
    SourcePermissions,
    SourceFigureAndFootnotes,
};
