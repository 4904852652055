import React, { Component } from 'react';
import { ThemeContext } from './components/Core/ThemeContext';

class Privacy extends Component {
    static contextType = ThemeContext;

    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    componentDidMount() {
        this.context.loading(false);
    }

    componentWillUnmount() {
        console.log('Will Unmount');
    }

    render() {
        // return(<li key={index}>{index}:{value}</li>);
        document.title = 'ai|Privacy';
        return (
            <div style={{ padding: 25 }}>
                <br />
                <h4>Privacy</h4>
                <br />
                <p>
                    ai-BIBLE is published by <a href="http://www.theshapeofsound.com">Shape of Sound</a>. Shape of Sound is dual registered in the UK and Australia, ai-BIBLE is published by the Australian business and comes under the Australian privacy act. The business goal is not to collect people's personal data; however we need or may encounter a certain amount in the improvement process of ai-BIBLE. For instance, ai-BIBLE uses a cookieliess analytics solution and the chat bot transcripts need to be collected and reviewed periodically.
                </p>
                <br />
                <h5>
                    Collection
                </h5>
                <br />
                <p>
                    ai-BIBLE uses a cookieless analytics solution to capture events and basic metrics for understanging usage patterns and levels. 
                </p>
                <p>
                    Those basic metrics include users locale (e.g. en_US or uk_UA), users timezone (e.g. Europe/Helsinki), the URL of the previous webpage from which a page was opened, the page user currently views (e.g. /hello), perf - an object which contains performance metrics related to the page load, users IP Address and User Agent.
                </p>
                <p>
                    When subscribing to devotionals or newsletters or sending emails then your email address will be stored.
                </p>
                {/* <p>
                    ai-BIBLE has a standard analytics solution that aggregates information about a user so that it is possible to get an idea of the most popular searches and who is using the site, who links to the site etc.
                </p> */}
                <p>
                    ai-BIBLE stores transcripts of the chats had with the chat bot, some of those transcripts are received by openai's chatGPT API also. This is collected to help improve the chat bot, and shared to provide a failover to openai's chatGPT if the bot cannot answer your question. For this reason we ask that you do not enter identifying information into the chat bot. To contact ai-BIBLE then please use the contact form rather than entering information into the chatbot as long term consideration is being given to adding tools to deliberately strip personally identifying information from chat transcripts.
                </p>
                <p>
                    The new labs feature in ai-BIBLE uses localStorage to store information that is submitted for the sake of the operation of the labs feature, e.g. the calorie counter saves the number of calories counted into localStorage so that a count can be maintained. This information is essential for operation of the feature.
                </p>
                <br/>
                <h5>
                    Disclosure
                </h5>
                <br />
                <p>
                    ai-BIBLE only shares queries with third party ai providers (in particular openai's chatGPT). In the future it could be the case that other 3rd parties are consulted to assist with product improvement, product ownership or review of the product. In theory investors or governing bodies may need to review some of the analytics information and potentially see some transcripts, and it may be that broad trends are used in public communications. The information collection by ai-BIBLE is stored on 3rd party servers (AWS / Google), see the section on storage for that.
                </p>
                <p>
                    ai-BIBLE does share queries with openai that are unidentified as long as PII information is not included in the text queries sent to the bot.
                </p>
                <p>
                    Information like calory counts that are added to localStorage in the app are not shared.
                </p>
                <br />
                <h5>
                    Storage
                </h5>
                <br />
                <p>
                    Any information collected in the labs section of the app is only stored on your local device and not online.
                </p>
                <p>
                    Any information collected is stored in cloud solutions such as Amazon Web Services or Google Cloud Platform, with the exception of direct emails that are stored in email accounts. The information is stored in protected accounts. Unidentified sentences in transcripts are held indefinitely, email addresses for devotionals are deleted once the devotional is complete, email addresses for newsletters are kept until unsubscribed and unverified email addresses remain in the database until verified. The analytics solution deletes information after 26 months, this is subject to change as I would like to reduce this.
                </p>
                <br />
                <h5>
                    Modification
                </h5>
                <br />
                <p>
                    Information in the labs section of the app can be modified using the tool used to add the data in the first place. If you would like to delete the data then the manage data tool in the labs section will do that for you.
                </p>
                <p>
                    All information is de-identified (except for email addresses for subscriptions and direct contact) and so it may not be possible to modify the data. You can use the google analytics opt out tool to avoid being tracked by this site:
                </p>
                <a href="https://tools.google.com/dlpage/gaoptout">
                    https://tools.google.com/dlpage/gaoptout
                </a>
                <br />
                <br />
                <p> 
                    If you have accidentally disclosed personal information to the chat bot then use the contact form on this website and request it is deleted. Because the information is not identified then please provide something you typed in, or a date that you entered the information. Please do get in touch if any information is incorrectly entered or you have any concerns.
                </p>
                <br />
                <h5>
                    Complaints
                </h5>
                <br />
                <p>
                    ai-BIBLE is run by Shape of Sound and comes under the Australia Privacy Act. It is preferred that complaints can be resolved by contacting Shape of Sound, you can get in contact with Alistair de Blacquiere-Clarkson at:
                </p>
                <a href="https://www.ai-bible.com/contact">
                    https://www.ai-bible.com/contact
                </a>
                <br />
                <br />
                <p> 
                    You are free also to make a complaint to the information commissioner:
                </p>
                <a href="https://www.oaic.gov.au/privacy/privacy-complaints">
                    https://www.oaic.gov.au/privacy/privacy-complaints
                </a>
            </div>
        );
    }
}

export default Privacy;
