import React, { Component } from 'react';
import '../../App.css';
import { DailyLocalStorageStore } from '../../components/Storage/LocalStorage';
import ReactJson from 'react-json-view'

const storage = new DailyLocalStorageStore('aib-calories-log');

function getDateKey(date)
{
    return date.toLocaleDateString('en-CA')
    //return date.toISOString().split('T')[0];
}

const PageDebug = () => {
    const json = storage.getAll();

    const debugJSON = {
        "today": getDateKey(new Date()),
        "calories": json || {}
    }

    console.log('JSON', debugJSON)


    return (
        <>
            <p>
                {new Date() + ""}
            </p>
            <ReactJson src={debugJSON} name={"debug"}>
            </ReactJson>
        </>
    )
}

export default PageDebug;